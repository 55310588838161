
import { Component, Vue, Watch } from "vue-property-decorator";

class Question {
  public index = -1;

  constructor(
    public question: string,
    public answer: string,
    public id?: string,
    public expanded: boolean = true
  ) {}
}

const questions: Question[] = [
  new Question(
    "Кто мы такие и чем занимаемся?",
    `SKINSPLUS - это надежная платежная система, использующая предметы Steam в качестве валюты. Подключайтесь к нам и Вы поймете на сколько это быстро и удобно!`
  ),
  new Question(
    "Как продать вам скин и получить за него деньги?",
    `У нас все настолько просто и быстро, но все-таки расскажем по-подробнее:<ol> <br>
<li>Авторизуйтесь на сайте</li>
<li>Вставьте трейд ссылку для прогружения вашего инвентаря</li>
<li>Ознакомьтесь с ценами и выберите скины на продажу</li>
<li>Выберите удобный способ выплаты и введите необходимые данные(номер кошелька, адрес электронной почты)</li>
<li>Отправьте игровые предметы нашему боту</li>
</ol> <br>
И не переживайте, если вы забудете добавить в оффер какой-либо скин или же наоборот добавите лишний - мы все равно примем трейд и сделаем выплату исходя из тех цен, что были вам показаны ранее!`
  ),
  new Question(
    "Почему я могу вам доверять?",
    `Мы работаем в данной сфере более 6 лет, попробуйте для начала с маленькой суммы, если беспокоитесь. <br>
Мы уверены, вам с нами понравится!`
  ),
  new Question(
    "Почему цены выше, чем на других сервисах моментальной скупки?",
    `Мы используем передовые алгоритмы оценки внутриигровых предметов и не ограничиваемся одним лишь провайдером цен для достижения оптимального коэффициента на покупку игровых предметов!`
  ),
  new Question(
    "Какие предметы я могу продать?",
    `Мы покупаем предметы от 5 рублей и до 200 000 рублей!`
  ),
  new Question(
    "Почему некоторые предметы я не могу продать?",
    `<ol><li>Скин слишком дешевый</li>
<li>Скин слишком дорогой</li>
<li>Скин считает «бущенным» и мы не можем дать на него актуальный прайс сейчас, отпишите в поддержку для уточнения данного вопроса</li></ol>`
  ),
  new Question(
    "Почему не пришли деньги?",
    `Статус вашей заявки вы можете отслеживать в личном профиле, но вот основные причины: <ol> <br>
<li>Вы указали неверные реквизиты</li>
<li>У вас отключен гуард и трейд принимается с 2-недельной задержкой(отмените в таком случае свой трейд)</li>
<li>Проблемы на стороне агрегатора платежей</li></ol>`
  ),
  new Question(
    "Куда обратиться для решения моей проблемы?",
    `У нас есть поддержка, которая работает через Telegram, для получения помощи перейдите по ссылке <a style="text-decoration: underline" class="text-primary text-weight-bold" href="https://t.me/skinsplus_support" target="_blank">@skinsplus_support</a>`
  )
].map((q, i) => {
  q.index = i;
  return q;
});

@Component({
  components: {},
  meta: {
    title: "Часто задаваемые вопросы"
  }
})
export default class FAQ extends Vue {
  public questions: Question[] = questions;
  public searchQuestion: Question | null = null;
  public questionsOptions: Question[] = questions;

  @Watch("searchQuestion")
  scrollToQuestion(question) {
    if (question) {
      const selector = "#" + (question.id || `question${question.index}`);
      this.$scrollTo(selector, 500);
    }
  }

  filterOptions(val, update, abort) {
    if (!val) {
      update(() => {
        this.questionsOptions = questions.slice(0, 5);
      });
    } else {
      update(() => {
        const needle = val.toLowerCase();
        this.questionsOptions = questions
          .filter(({ question }) => question.toLowerCase().indexOf(needle) > -1)
          .slice(0, 5);
      });
    }
  }
}
